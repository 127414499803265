<template>
  <section>
    <el-row
      v-for="(discount, index) in form"
      :key="index"
      :gutter="20"
      class="mb-4 last:mb-0"
    >
      <el-col :xl="5" :lg="6" :md="10" :sm="10" class="xs:mb-2">
        <!-- Amount -->
        <th-currency-input
          class="w-full"
          :model-value="discount.amount"
          :currency="discount.currency"
          @update:modelValue="(v) => setAmount(index, v)"
        />
      </el-col>

      <el-col :xl="16" :lg="14" :md="14" :sm="14">
        <!-- Currency -->
        <th-currency-select
          v-if="currencies.length > 1"
          class="inline-block w-32"
          :model-value="discount.currency"
          :filtered-currencies="usedCurrencies"
          :allowed-currencies="currencies"
          @update:modelValue="(v) => setCurrency(index, v)"
        />

        <!-- Delete -->
        <el-button
          icon="Delete"
          class="ml-2 el-button--text-icon"
          plain
          @click="deleteDiscount(index)"
        />

        <!-- Add -->
        <el-button
          v-if="form.length === index + 1 && currencies.length > 1"
          :disabled="!noCurrencies"
          :aria-label="$t('common.actions.addmore')"
          class="el-button--primary-icon ml-2"
          icon="Plus"
          plain
          @click="addNew"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { computed } from 'vue'
import { mapGetters } from 'vuex'
import currenciesMixin from '@/views/products/v2/mixins/currencies'

export default {
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  setup(props, { emit }) {
    const form = computed({
      get() {
        return props.modelValue
      },
      set(modelValue) {
        emit('update:modelValue', modelValue)
      }
    })
    const {
      defaultCurrency,
      noCurrencies,
      availableCurrencies,
      usedCurrencies
    } = currenciesMixin(form)
    return {
      defaultCurrency,
      noCurrencies,
      availableCurrencies,
      usedCurrencies,
      form
    }
  },

  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies'
    })
  },

  methods: {
    getDiscount() {
      return {
        amount: null,
        currency:
          !this.form || this.form.length === 0
            ? this.defaultCurrency
            : this.availableCurrencies[0]
      }
    },

    setCurrency(index, val) {
      this.setValue('currency', index, val)
    },

    setAmount(index, val) {
      this.setValue('amount', index, val)
    },

    setValue(prop, index, value) {
      const arr = [...this.form]
      arr[index] = { ...this.getDiscount(), ...this.form[index] }
      arr[index][prop] = value
      this.form = arr
    },

    addNew() {
      this.form = this.form.concat(this.getDiscount())
    },

    deleteDiscount(deleteIndex) {
      this.form = this.form.filter((_, i) => i !== deleteIndex)
      if (!this.form.length) this.addNew()
    }
  }
}
</script>
