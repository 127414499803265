<template>
  <section>
    <!-- Allow disocunts -->
    <th-wrapper
      :title="$t('pages.settings.pos.discounts.stacked_on_product.text')"
    >
      <div class="flex items-center">
        <th-popover
          :text="$t('pages.settings.pos.discounts.stacked_on_product.popover')"
        />
        <el-switch
          v-model="stackedOnProduct"
          active-text="Enabled"
          class="ml-2"
        />
      </div>
    </th-wrapper>

    <!-- Variable discount -->
    <th-wrapper :title="$t('pages.settings.pos.discounts.variable.title')">
      <!-- Discount can exceed product price -->
      <div class="mb-6">
        <el-switch
          v-model="variableExceedProductPrice"
          :active-text="
            $t('pages.settings.pos.discounts.can_exceed_cart_item_total.text')
          "
        />
      </div>

      <!-- Relative discount to product price cannot exceed -->
      <div class="mb-6">
        <th-input-title
          :title="
            $t('pages.settings.pos.discounts.relative_cannot_exceed.text')
          "
          :info="$t('pages.settings.pos.discounts.maximum_rate.popover')"
        />
        <th-number-input
          v-model="variableMaximumRate"
          percent
          :upper-limit="1.0"
          :locale="$i18n.locale"
          class="w-40 max-w-full"
        />
      </div>

      <!-- Variable discount to product price cannot exceed -->
      <div>
        <th-input-title
          :title="
            $t('pages.settings.pos.discounts.absolute_cannot_exceed.text')
          "
          :info="$t('pages.settings.pos.discounts.maximum_rate.popover')"
        />
        <currency-rates v-model="variableMaximumValue" />
      </div>
    </th-wrapper>

    <!-- Fixed -->
    <th-wrapper :title="$t('pages.settings.pos.discounts.fixed.title')">
      <!-- Discount can exceed product price -->
      <div class="mb-6">
        <el-switch
          v-model="fixedExceedProductPrice"
          :active-text="
            $t('pages.settings.pos.discounts.can_exceed_cart_item_total.text')
          "
        />
      </div>

      <!-- Relative discount to product price cannot exceed -->
      <div class="mb-6">
        <th-input-title
          :title="
            $t('pages.settings.pos.discounts.relative_cannot_exceed.text')
          "
          :info="$t('pages.settings.pos.discounts.maximum_rate.popover')"
        />
        <th-number-input
          v-model="fixedMaximumRate"
          class="w-40 max-w-sm"
          percent
          :locale="$i18n.locale"
          :upper-limit="1.0"
        />
      </div>

      <!-- Variable discount to product price cannot exceed -->
      <div>
        <th-input-title
          :title="
            $t('pages.settings.pos.discounts.absolute_cannot_exceed.text')
          "
          :info="$t('pages.settings.pos.discounts.maximum_rate.popover')"
        />
        <currency-rates v-model="fixedMaximumValue" />
      </div>
    </th-wrapper>

    <!-- Cart limit -->
    <th-wrapper :title="$t('pages.settings.pos.discounts.cart_limit.title')">
      <!-- Relative limit to the cart value cannot exceed -->
      <div class="mb-6">
        <th-input-title
          :title="
            $t('pages.settings.pos.discounts.cart.relative_cannot_exceed.text')
          "
          :info="$t('pages.settings.pos.discounts.cart.maximum_rate.popover')"
        />
        <th-number-input
          v-model="cartLimitMaximumRate"
          percent
          :upper-limit="1.0"
          :locale="$i18n.locale"
          class="w-40 max-w-sm"
        />
      </div>

      <!-- Absolute limit to cart value cannot exceed -->
      <div>
        <div class="flex place-content-start el-form-item__label">
          <th-popover
            :text="$t('pages.settings.pos.discounts.cart.maximum_rate.popover')"
            placement="top"
            class="pr-2"
          />
          <label>
            {{
              $t(
                'pages.settings.pos.discounts.cart.absolute_cannot_exceed.text'
              )
            }}
          </label>
        </div>
      </div>
      <currency-rates v-model="cartLimitMaximumValue" />
    </th-wrapper>
  </section>
</template>

<script>
import safeGet from 'just-safe-get'
import CurrencyRates from './components/currency-discount'

const setDefault = (v, defaultBool) =>
  typeof v === 'boolean' ? v : defaultBool

export default {
  components: {
    CurrencyRates
  },
  computed: {
    defaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },
    stackedOnProduct: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.stacked_on_product'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.stacked_on_product',
          value: setDefault(newValue, true)
        })
      }
    },
    variableExceedProductPrice: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart_item.variable.can_exceed_cart_item_total'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path:
            'discounts.limits.cart_item.variable.can_exceed_cart_item_total',
          value: setDefault(newValue, false)
        })
      }
    },
    variableMaximumRate: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart_item.variable.maximum_rate'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart_item.variable.maximum_rate',
          value: newValue || null
        })
      }
    },
    variableMaximumValue: {
      get: function () {
        const data = safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart_item.variable.maximum_value'
        )
        return data && data.length
          ? data
          : [{ amount: null, currency: this.defaultCurrency }]
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart_item.variable.maximum_value',
          value: newValue || null
        })
      }
    },
    fixedExceedProductPrice: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart_item.fixed.can_exceed_cart_item_total'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart_item.fixed.can_exceed_cart_item_total',
          value: setDefault(newValue, false)
        })
      }
    },
    fixedMaximumRate: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart_item.fixed.maximum_rate'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart_item.fixed.maximum_rate',
          value: newValue || null
        })
      }
    },
    fixedMaximumValue: {
      get: function () {
        const data = safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart_item.fixed.maximum_value'
        )
        return data && data.length
          ? data
          : [{ amount: null, currency: this.defaultCurrency }]
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart_item.fixed.maximum_value',
          value: newValue || null
        })
      }
    },
    cartLimitMaximumRate: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart.maximum_rate'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart.maximum_rate',
          value: newValue || null
        })
      }
    },
    cartLimitMaximumValue: {
      get: function () {
        const data = safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'discounts.limits.cart.maximum_value'
        )
        return data && data.length
          ? data
          : [{ amount: null, currency: this.defaultCurrency }]
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'discounts.limits.cart.maximum_value',
          value: newValue || null
        })
      }
    }
  }
}
</script>
